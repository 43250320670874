import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

const ContainerStyle = styled.section`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  font-family: "League Spartan", sans-serif;
  font-weight: 300;
  justify-content: space-around;
  flex-wrap: nowrap;
  padding: 4rem 2rem; /* Increased padding for better spacing */
  margin: 0 auto;

  /* Background color logic with gradient */
  background: ${({ first, secondary, contact, gradient }) =>
    gradient
      ? "linear-gradient(180deg, #00264c, rgb(242, 232, 223))"
      : first
      ? "rgb(242, 232, 223)"
      : secondary
      ? "rgb(242, 232, 223)"
      : contact
      ? "#00264c"
      : "rgb(242, 232, 223)"};


  color: ${({ contact, gradient }) =>
    contact || gradient ? "rgb(242, 232, 223)" : "#162a3e"};

  > * {
    padding: 1rem 0;
  }

  h2 {
    text-decoration: underline;
    text-align: center;
    ${({ noTitle }) => noTitle && "display: none;"}
    color: ${({ contact, gradient }) =>
      contact || gradient ? "rgb(242, 232, 223)" : "#162a3e"};
  }

  p {
    color: ${({ first, secondary, contact }) =>
      contact
        ? "rgb(242, 232, 223)"
        : first || secondary
        ? "#162a3e"
        : "#162a3e"};
    padding: 1rem 2rem;
    margin: 0 auto;
    max-width: 1200px;
  }

  p,
  form {
    padding: 1rem 2rem;
    margin: 0 auto;
    max-width: 1200px;
  }

  label {
    display: flex;
    flex-direction: column;
    padding: 1rem 0;
  }

  input,
  textarea {
    padding: 0.5rem;
    color: #162a3e;
    background-color: rgb(242, 232, 223);
  }

  textarea {
    height: 8rem;
  }
`

const Container = ({
  title,
  first,
  secondary,
  noTitle,
  contact,
  gradient,
  children,
  ...props
}) => (
  <ContainerStyle
    {...props}
    first={first}
    secondary={secondary}
    contact={contact}
    gradient={gradient}
    noTitle={noTitle}
  >
    <h2>{title}</h2>
    {children}
  </ContainerStyle>
)

Container.propTypes = {
  first: PropTypes.bool,
  secondary: PropTypes.bool,
  contact: PropTypes.bool,
  gradient: PropTypes.bool,
  noTitle: PropTypes.bool,
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
}

Container.defaultProps = {
  first: false,
  secondary: false,
  contact: false,
  gradient: false,
  noTitle: false,
  title: "",
}

export default Container
